import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../Layout/Modals/Modal';
import secretValidation from '../../../assets/icons/secret-validation-circle.png'
import JoinPager from '../../../Layout/JoinPager/JoinPager';
import inputright from '../../../assets/icons/input-right.svg'
import JoinUsService from '../JoinUsService';
import MvnoModal from '../../../Layout/Modals/MvnoModal';

const NiceGuardValidation = () => {
    const navigate = useNavigate()
    const location = useLocation()
    //Input 값들
    //은행 정보
    const [name,setName] = useState('')
    const [telecom,setTelecom] = useState('')
    const telecomName = ["","SKT","KT","LGU+","","알뜰폰 SKT","알뜰폰 KT","알뜰폰 LGU+"]
    const [phone,setphone] = useState("")
    const [phoneHead,setPhoneHead] = useState("")
    const [idCardFront,setIdCardFront] = useState('')
    const [sexNumber,setSexNumber] = useState('')
    const [device,setdevice] = useState(false)
    //알림 모달
    const [modal_status,setmodal_status] = useState('')
    const [modal,setModal] = useState(false)
    const [modal_title,setmodal_title] = useState('')
    const [modal_contents,setmodal_contents] = useState('')
    //NVMO 모달
    const [nvmoModal,setNvmoModal] = useState(false)
    //selector view 관련
    const [selectView,setSelectView] = useState(false)
    // 핸드폰 인증 관련
    const [inputFocus,setInputFocus] = useState(false)

    useEffect(()=>{
        const user = navigator.userAgent
        if(user.indexOf('iPhone') > -1){
            setdevice(true)
        }else if(user.indexOf('Android') > -1){
            setdevice(true)
        }else{
            setdevice(false)    
        }
    },[])

    const closeModalView = () => {
        setModal(false)
    }
    const toSms = async() => {
        if(!checkAdult()){
            getSmsNumber()
        }else{
            setmodal_title('알림')
            setmodal_contents('만 19세 이상의 실제 법정대리인 정보로 보호자 인증이 필요합니다.')
            setModal(true)
        }
    }
    //sms 인증 문자 유저에게 보내기
    const getSmsNumber = async() => {
        const body = {
            phone : phoneHead? phoneHead:'010' + phone.replace('-',''),
            name : name,
            rr_number : idCardFront + sexNumber,
            telco : telecom
        }
        //사용자에게 문자 보내는 로직
        try{
            const smsSendResult = await JoinUsService.postNiceIdSMS(body)
            const requestSeqCode = smsSendResult.request_seq_code
            const responseSeqCode = smsSendResult.response_seq_code
            //문자보내는거 확인됬으면
            //SMS 번호 입력 페이지로 이동
            navigate("../sms",{state:{
                last:'NiceGuardValidation',
                name:name,
                phone:phoneHead? phoneHead:'010' + phone.replace('-',''),
                birthday : birthDayReplace(idCardFront,sexNumber),
                request_seq_code : requestSeqCode,
                response_seq_code : responseSeqCode,
                child_name: location.state.name,
                child_birth_at: location.state.birthday,
                child_phone:location.state.phone,
                consent: true,
                cicode : location.state.cicode,
                dicode : location.state.dicode
            }})
        }catch(error){
            setmodal_title('알림')
            setmodal_contents('인증 문자를 받을 수 없습니다. \n 통신사에 가입된 본인 정보가 맞으신가요? \n 입력하신 정보를 다시 확인해주세요.')
            setModal(true)
        }
    }
    const birthDayReplace = (idcardFront,sexNum) => {
        var divisionCode = sexNum;
        var dateOfBirth = ""
        if(divisionCode == 1 || divisionCode == 2 || divisionCode == 5 || divisionCode == 6){
			dateOfBirth = "19"+idcardFront;
		}else if(divisionCode == 3 || divisionCode == 4 || divisionCode == 7 || divisionCode == 8){
			dateOfBirth = "20"+idcardFront;
		}else if(divisionCode == 9 || divisionCode == 0){
			dateOfBirth = "18"+idcardFront;
		}
        dateOfBirth.replace(/^(\d{4})(\d{2})(\d{1})$/, `$1-$2-$3`);
        var result1 = [dateOfBirth.substring(0,4),dateOfBirth.substring(4,6),dateOfBirth.substring(6,8)]
        dateOfBirth = result1[0]+"-"+ result1[1]+"-"+ result1[2]
        return dateOfBirth;
    }
    const checkAdult = (idcardFront,sexNum)=>{
        var divisionCode = sexNum;
        var dateOfBirth = ""
        if(divisionCode == 1 || divisionCode == 2 || divisionCode == 5 || divisionCode == 6){
			dateOfBirth = "19"+idcardFront;
		}else if(divisionCode == 3 || divisionCode == 4 || divisionCode == 7 || divisionCode == 8){
			dateOfBirth = "20"+idcardFront;
		}else if(divisionCode == 9 || divisionCode == 0){
			dateOfBirth = "18"+idcardFront;
		}
        var today = new Date();
        var yyyy = today.getFullYear();
        var mm = today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1); // getMonth()
        var dd  = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        return parseInt(String(yyyy) + String(mm) + String(dd)) - parseInt(dateOfBirth) - 190000 < 0
    }
    return (
        <>
        <div className='nice-valudation-page'>
            <JoinPager
                num={2}
            ></JoinPager>
            <p className='info'>보호자 정보 입력</p>
            <p className='sub-info'>보호자 명의의 휴대전화 번호로만 보호자 인증이 가능합니다. 인증하실 보호자님의 통신사 정보를 빠짐없이 입력해주세요.</p>
            <div className='items item-first'>
                <div className='input-colum-title'>
                    <p>이름</p>
                </div> 
                <input type='text' value={name} className='input-colum' onChange={(event)=>{
                    var data = event.target.value
                    data = data.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,'')
                    setName(data)
                }} onBlur={()=>{
                    var trimString = name.trim()
                    const check_eng = /[a-zA-Z]/;    // 문자 
                    const check_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
                    if(check_kor.test(trimString) && check_eng.test(trimString)){            
                        setmodal_status(0)
                        setmodal_title('알림')
                        setmodal_contents('이름을 정확히 입력해주세요.')
                        setModal(true)
                        setName("")
                    }else{
                        if(check_kor.test(trimString)){trimString = trimString.replace(/ /gi,"")}
                        setName(trimString)
                    }
                    }} placeholder='이름을 입력해주세요'></input>
                <div className='input-colum-comment'>
                    <p>본인 이름을 한글 또는 영문으로 입력해주세요. (통신사 가입 명의)</p>
                </div>
            </div>
            <div className='items'>
                <div className='input-colum-title'>
                    <p>생년월일/성별</p>
                </div>
                <div className='input-double-flex'>
                    <input type='text' value={idCardFront} className='input-colum' onChange={(event)=>{
                        var data = event.target.value
                        data = data.replace(/[^0-9]/g, '')
                        data = data.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`)
                        if(data.length < 7){
                            setIdCardFront(data)
                        }
                    }} placeholder='주민번호 앞 번호'></input>
                    <div className='id-card-number'>
                        <p>-</p>
                        <input type='text' value={sexNumber} className='input-colum' onChange={(event)=>{
                            var data = event.target.value
                            data = data.replace(/[^0-9]/g, '')
                            if(data.length < 2){
                                setSexNumber(data)}
                        }} placeholder='1'></input>
                        <img src={secretValidation}></img>
                        <img src={secretValidation}></img>
                        <img src={secretValidation}></img>
                        <img src={secretValidation}></img>
                        <img src={secretValidation}></img>
                        <img src={secretValidation}></img>
                    </div>
                </div>
                <div className='input-colum-comment'>
                    <p>주민번호 앞 번호(생년월일), 뒷 번호(성별) 첫 자리까지만 입력해주세요.</p>
                </div>
            </div>
            <div className='items'>
                <div className='input-colum-title'>
                    <p>통신사</p>     
                </div>
                <div className='btn-flex'>
                    <button onClick={() => {setTelecom(1)}} className={telecom === 1 ? "telecom active":"telecom not-active"}>SKT</button>
                    <button onClick={() => {setTelecom(2)}} className={telecom === 2 ? "telecom active":"telecom not-active"}>KT</button>
                    <button onClick={() => {setTelecom(3)}} className={telecom === 3 ? "telecom active":"telecom not-active"}>LGU+</button>
                    <button onClick={() => {setNvmoModal(true)}} className={telecom >= 5 ? "telecom active":"telecom not-active"}>{telecom >= 5 ? telecomName[telecom]:"알뜰폰"}</button>
                </div>
            </div>
            <div className='items'>
                <div className='input-colum-title'>
                    <p>휴대전화 번호</p>
                </div>
                <div className='input-double'>
                    {!device? <div 
                    onClick={() => {setSelectView(!selectView)}} className={phoneHead? "selector selected":"selector"} >
                        {phoneHead? phoneHead:'010'} &nbsp;&nbsp;&nbsp;
                        <img src={inputright} alt='dropdown'></img>
                        {selectView ? <ul className="phone-list">
                            <li onClick={()=>{setPhoneHead('010')}} className="phone-item"><p>010</p></li>
                            <li onClick={()=>{setPhoneHead('011')}} className="phone-item"><p>011</p></li>
                            <li onClick={()=>{setPhoneHead('019')}} className="phone-item"><p>019</p></li>
                        </ul>:<></>}
                    </div>:<select onChange={(event)=>{
                        setPhoneHead(event.target.value)
                    }} className='input-selector'>
                        <option value='010'>010&nbsp;&nbsp;&nbsp;</option>
                        <option value='011'>011&nbsp;&nbsp;&nbsp;</option>
                        <option value='019'>019&nbsp;&nbsp;&nbsp;</option>
                    </select>}  
                    <input type='text' value={phone} className='input-colum' onChange={(event)=>{
                        var data = event.target.value
                        data = data.replace(/[^0-9]/g, '')
                        data = data.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`)
                        setphone(data)}}
                        onFocus = {()=>setInputFocus(true)}
                        onBlur = {()=>setInputFocus(false)} 
                        placeholder='휴대전화 번호를 입력해주세요'></input>
                </div>
                <hr className={inputFocus? "input-focus-true":"input-focus-false"}></hr>
                <div className='input-colum-comment'>
                    <p>휴대전화 번호는 숫자로만 입력해주세요.</p>
                </div>
            </div>
            <button disabled={phone && name && telecom && idCardFront && sexNumber ? false:true} onClick={() => toSms()}>인증 문자 받기</button>
            {modal? <Modal
                title={modal_title}
                contents={modal_contents}
                closeModal = {closeModalView}
                ></Modal>:<></>}
            {nvmoModal? <MvnoModal SelectData={(data)=>{setTelecom(data); setNvmoModal(false)}}></MvnoModal>:<></>}
        </div>
        </>
    );
};

export default NiceGuardValidation;